ptz-header-error {
  .ptz-header-error {
    display: none;

    &-show {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: $petz-opacity-xl;
      color: $petz-color-neutral-dark;
      font-weight: $petz-font-weight-regular;
      font-size: $petz-font-size-2xs;
      line-height: $petz-font-lineheight-lg;
      background: $petz-color-neutral-white;
    }
  }
}
