import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'ptz-header-error',
  styleUrl: 'ptz-header-error.scss',
  shadow: false,
})

export class PTZHeaderError {
  /** Deve ou não mostrar a mensagem */
  @Prop() show: boolean;

  render() {
    return (
      <Host>
        <div class={`ptz-header-error ${this.show ? 'ptz-header-error-show' : ''}`}>
          <ptz-icon brand='ptz' variant='line' color='neutral-dark' name='sync' size='md' />
          <span>Não foi possível carregar.</span>
          <span>Verifique sua internet e tente outra vez.</span>
        </div>
      </Host>
    );
  }
}
